































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyRoutes } from "@/shared/router/company";
import Course from "@/shared/modules/courses/course.model";
import FormBase from "@/shared/classes/form/form-base";
import __ from "@/shared/helpers/__";

@Component({
  methods: { __ },
})
export default class CourseTestFinished extends Vue {
  @Prop() course!: Course
  @Prop() testScore!: number
  @Prop() testPassed!: boolean
  @Prop() wrongTests!: Record<string, any>[]
  @Prop() form!: FormBase


  onClose() {
    this.$router.push({ name: CompanyRoutes.coursesIndex })
  }
}
